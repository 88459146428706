/*fonts*/
@font-face {
  font-family: 'LemonadeBold';
  src: url(fonts/LemonadeBold.ttf);
}

@font-face {
  font-family: 'MaisonNeue';
  src: url(fonts/MaisonNeue.ttf);
}

body {
  background-color: #000 !important;
}

.bgc-body {
  background-image: url("../public/assets/bg-body.png") !important;
  background-color: unset !important;
}

.primary-font {
  font-family: 'LemonadeBold' !important;
}

.secondary-font {
  font-family: 'MaisonNeue' !important;
}

.social-icon {
  width: 25px;
}

.fs-sectio1 {
  font-size: 8.5rem;
}

.fs-item-title{
  font-size: 1.8rem;
}

.fs-item-description{
  font-size: 1.25rem;
}

.fss-feedbak-s {
  font-size: calc(1.275rem + .3vw) !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 5rem !important;
  height: 5rem !important;
}

.px-socials {
  padding-left: 10rem !important;
  padding-right: 10rem !important;
}

.text-link {
  text-decoration: none;
  color: #e51d51;
}

.list-group .list-group-item:first-child:before {
  padding-left: 0px;
  content: none;
}

.list-group .list-group-item:before {
  content: "|";
  position: absolute;
  left: 0;
  top: 0;
  color: #fff;
  font-size: 25px;
}

.text-shadow {
  font-size: 4rem !important;
  font-weight: bold !important;
  text-shadow: -1px 2px #e51d51;
}

.text-social {
  width: 100%;
  text-align: center;
  border-bottom: 2px solid #e51d51;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.text-social span {
  background: #000;
  padding: 0 10px;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  background-color: #e51d51 !important;
  width: 40px !important;
  height: 40px !important;
}

.btn-outline-danger {
  color: #fff !important;
  border: none !important;
  width: 40px !important;
  height: 40px !important;
}

.max-w-scale {
  max-width: 650px !important;
}

.form-check-input:checked[type="radio"] {
  --bs-form-check-bg-image: unset !important;
}

.form-check-input:checked {
  background-color: #e51d51 !important;
  border-color: #e51d51 !important;
}

.btn-submit{
  background-color: #e51d51 !important;
  border-color: #e51d51 !important;
  width: 200px;
  font-family: 'MaisonNeue' !important;
  font-size: 20px !important;
}

@media only screen and (max-width: 500px) {
  .fs-sectio1 {
    font-size: 6.5rem;
  }
  .text-shadow {
    font-size: 2rem !important;
  }
  .px-socials {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .fs-item-title{
    font-size: 1.3rem;
  }
  .fs-item-description{
    font-size: 0.7rem;
  }
  .fs-xs-social{
    font-size: 1.3rem;
  }
  .fs-xs-feedbak-q{
    font-size: 1.5rem;
  }
  .fss-feedbak-s {
    font-size: 1.2rem;
  }
}